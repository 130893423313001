import React from "react"
import { Link } from "gatsby"
import logo from "../assets/images/logo2.png"

const ComingSoon = () => {
  return (
    <React.Fragment>
      <div className="coming-soon-area">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="coming-soon-content">
              <Link to="/" className="logo">
                <img src={logo} alt="logo" />
              </Link>
              <h2>Page Not Found</h2>

              <div
                id="timer"
                className="flex-wrap d-flex justify-content-center"
              >
                <div
                  id="days"
                  className="align-items-center flex-column d-flex justify-content-center"
                >
                  4<span></span>
                </div>
                <div
                  id="hours"
                  className="align-items-center flex-column d-flex justify-content-center"
                >
                  0<span></span>
                </div>
                <div
                  id="minutes"
                  className="align-items-center flex-column d-flex justify-content-center"
                >
                  4<span></span>
                </div>
              </div>

              <form className="newsletter-form">
                <Link to="/" className="default-btn">
                  <i className="flaticon-right"></i>
                  Go To Homepage <span></span>
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ComingSoon
